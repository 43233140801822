.list {
    list-style-type: disc;
    margin-left: 1.2em;
}

.list li {
    margin-top: 0.5em;
}

.large {
    font-size: 1.5em;
}

.images {
    display: flex;
    justify-content: space-between;
    column-gap: 2em;
}

.images > * {
    flex: 1 0 auto;
    max-width: 50%;
}

.images img {
    display: block;
    width: 100%;
}
