.content {
    display: flex;
    flex-direction: column;
    row-gap: 1em;
}

.okSign {
    color: var(--clr-green);
}

.errorSign {
    color: var(--clr-afRed);
}

.registeredPhone {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.registeredPhone > :last-child {
    justify-self: flex-end;
}

.checkbox {
    display: flex;
    align-items: center;
    column-gap: 0.5em;
}

.checkbox > * {
    cursor: pointer;
}

.buttonRight {
    display: flex;
    justify-content: flex-end;
}
