.instruction {
    background-color: #fdc84a;
    border-radius: 5px;
    padding: 0.25em 0.5em;
    font-size: 0.8em;
    font-weight: 600;
}

.instruction span {
    padding: 0 0.25em;
}

.error {
    color: var(--clr-afRed);
}

.success {
    color: var(--clr-green);
}
