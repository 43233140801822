.content {
    display: flex;
    flex-direction: column;
    row-gap: 1em;
}

.progressbar {
    border: 1px solid var(--clr-afGray);
    margin-bottom: 1em;
    border-radius: var(--br_small);
    overflow: hidden;
}

.bar {
    position: relative;
    height: 20px;
    background-color: var(--clr-lightBlue);
    transition-property: width;
    transition-timing-function: linear;
    width: 100%;
    border-radius: var(--br_small);
    /* border-top-right-radius: var(--br_small);
    border-bottom-right-radius: var(--br_small); */
}

.bar > span {
    position: absolute;
    white-space: nowrap;
    overflow: visible;
    font-size: 0.85em;
    left: 0;
    top: 0;
    padding: 0 0.5em;
    width: 100%;
    text-align: center;
    line-height: 20px;
}

.zero {
    width: 0;
}
