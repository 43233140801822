.content {
    display: flex;
    flex-direction: column;
    row-gap: 1em;
}

.checkboxes {
    display: flex;
    flex-direction: column;
    row-gap: 0.5em;
}

.checkbox {
    display: flex;
    column-gap: 0.3em;
    align-items: flex-start;
}

.checkbox > input {
    margin-top: 0.25em;
}

.checkbox > * {
    cursor: pointer;
}

.indent {
    padding-left: 1.1em;
}

.note {
    margin-top: 0.5em;
    background-color: var(--clr-planning);
    padding: 0.25em 0.5em;
    border-radius: var(--br_large);
}
