.buttons {
    display: flex;
    justify-content: space-between;
}

.buttons *:nth-child(2) {
    justify-self: flex-end;
}

.borderTop {
    padding-top: 1em;
    border-top: 1px solid #ccc;
}
