div.teaserButtons {
	display: flex;
	flex-wrap: wrap;
	gap: 1em;
}

.teaserButton {
	width: 180px;
	height: 150px;
	background-color: #e9e9e9;
	border-radius: 0.5em;
	position: relative;
	overflow: hidden;
	padding: 1em 1em 3em 1em;
}

.teaserButton:hover {
	cursor: pointer;
	background-color: #bfd1e7;
}

.teaserButton div {
	position: absolute;
	left: 0;
	bottom: 0;
	right: 0;
	text-align: center;
	font-weight: 600;
	background-color: #bfd1e7;
	line-height: 2em;
	user-select: none;
}

.teaserButton img {
	max-width: 100%;
	max-height: 100%;
	display: block;
	margin: auto;
	opacity: 0.5;
	transition: all 0.25s ease-in-out;
}

.teaserButton:hover div {
	background-color: #346bb3;
	color: white;
}

.teaserButton:hover img {
	opacity: 0.8;
	transform: scale(1.05);
}
