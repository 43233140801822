.label {
    display: inline-block;
    font-size: 0.85em;
    font-weight: 600;
}

.required {
    border-radius: var(--br_small);
    padding: 0 6px;
    color: white;
    line-height: 1.5em;
    background-color: var(--clr-green);
    margin-bottom: 1px;
}

.required::before {
    content: "✔ ";
    font-weight: 400;
}

.error {
    background-color: var(--clr-afRed);
}

.error::before {
    content: "✘ ";
}
